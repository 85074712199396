
  import Vue from 'vue'
  import { mapGetters, mapActions } from 'vuex'

  /**  Service **/

  /**  Views **/
  import Sidebar from './Sidebar.vue'

  export default Vue.extend({
    data: () => ({
      version: '',
      siteSelected: '',
      tmpSite: '',
      lang: ''
    }),

    components: {
      Sidebar
    },

    mounted() {
      this.version = process.env.VUE_APP_VERSION

      this.tmpSite = window.localStorage.getItem('SITE_SELECTED') as string

      if (this.tmpSite != null && this.tmpSite != undefined && this.tmpSite != '') this.siteSelected = this.tmpSite

      try {
        const lang_ = window.localStorage.getItem('LANGUAJE')

        if (lang_ && lang_ != '') {
          if (lang_ == 'en') this.lang = 'Spanish'
          else if (lang_ == 'es') this.lang = 'Inglés'
        }
      } catch {
        console.log('Error asignado el idioma')
      }
    },

    computed: {
      ...mapGetters({ curUserObject: 'auth/curUserObject', username: 'auth/username', token: 'auth/token', loading: 'app/loading', help: 'app/help' })
    },

    watch: {
      siteSelected(val) {
        this.setSite(val)
      }
    },

    methods: {
      ...mapActions({ login: 'auth/login', logout: 'auth/logout', toggleSideBar: 'app/toggleSideBar', changeLocale: 'app/changeLocale', toggleHelp: 'app/toggleHelp' }),

      setSite(site_id: string) {
        //Localstorage saca el site seleccionado y refresca la pagina para reenviar la peticion con la bodega nueva
        window.localStorage.setItem('SITE_SELECTED', site_id)
        if (this.tmpSite != site_id) location.reload()
      },

      goToDashboard() {
        this.$router.push({
          name: 'dashboard',
          params: {}
        })
      },
      changeLanguage() {
        let lang_ = window.localStorage.getItem('LANGUAJE') as string

        if (lang_ == 'en') lang_ = 'es'
        else lang_ = 'en'

        window.localStorage.setItem('LANGUAJE', lang_)
        this.changeLocale(lang_)
        window.location.reload()
      }
    }
  })
